import React, { Component } from 'react';
import TaskProfile from './TaskProfile';
import { TaskRichieste } from './TaskRichieste';
import authService from './api-authorization/AuthorizeService';

export class TaskDetail extends Component {
  static displayName = TaskDetail.name;

  constructor(props) {
    super(props);
    console.log(this.props.match.params.id);
    this.state = { currentCount: 0 };    
    this.idTask = this.props.match.params.id;    
    this.statoTask = this.props.match.params.stato;     
  }
  

  render() {    
    return (
      <div>        
        <TaskProfile id= {this.idTask}></TaskProfile>
        <TaskRichieste  idTask={this.idTask} statoTask={this.statoTask} ></TaskRichieste>
      </div>
    );
  }
}
