import React, { useCallback, useEffect }  from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import Moment from 'react-moment';
import moment from 'moment';

import GridRichiesteDetail from './GridRichiesteDetail';

import authService from './api-authorization/AuthorizeService'
import { Form, Col, Row } from 'react-bootstrap';

import toast, { Toaster } from 'react-hot-toast';

function CreateTaskModal(props) {

  // handle modal component
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  } 
  const handleShow = () => {
    setShow(true);
    props.notify_parent()
  }
  const [username, setUsername] = useState('');

  const fetchUserData = useCallback( async() => {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()]);        
        if(isAuthenticated){
          setUsername(user.name);                
        }        
  } , [] )
  
  useEffect(() => {
     fetchUserData()
  }, [fetchUserData]);    
  
  let today = moment().format("YYYY-MM-DD");
  let tomorrow = moment().add(1,'d').format("YYYY-MM-DD");
  
  const [dataCreazione, setDataCreazione] = useState(today);
  const [dataChiusura, setDataChiusura] = useState(tomorrow);
  
  const handleSave = (event) => {
    event.preventDefault();    
    alert(event.target.titolo.value);
    createTaskRichieste();
    handleClose();
  }

  // useEffect(() => { setCounter(1) }, [])
  //   console.log('inc counter');    
  //   console.log(counter)
    

  const handleSubmit = (event) => {    
    event.preventDefault();
    
    setDataCreazione(event.target.DataCreazione.value);
    setDataChiusura(event.target.DataChiusura.value);

    createTaskRichieste(event);
    
  }

  const createTaskRichieste = async function (event) {
    
    var titolo = event.target.Titolo.value;
    var dataCreazione = event.target.DataCreazione.value;
    var dataChiusura = event.target.DataChiusura.value;
    var note = event.target.Note.value;
    var utenteOwner = event.target.UtenteOwner.value;

    const token = await authService.getAccessToken();
    // Simple POST request with a JSON body using fetch    
    const payload = { gdprTask: {
                                  "titolo": titolo,
                                  "dataCreazione" : dataCreazione,
                                  "dataChiusura" : dataChiusura,
                                  "note": note,
                                  "utenteOwner": utenteOwner,
                                  "stato": "APERTO"
                                },
                      richieste : props.richieste
                    };
    
    const requestOptions = {        
        method: 'POST',
        headers: new Headers ({
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json' 
         }),
        body: JSON.stringify(payload)
    };    

    let message = null;
    const defaultMessage = 'Success!'

    const myPromise = new Promise((resolve, reject) =>
      fetch('richiesta/CreaTask', requestOptions)
        .then((res) => res.json())
        .then((data) => {                    
          if (data?.idTask !== 0 && data?.titolo) {                        
            message = 'Task creata con successo';            
            resolve();                     
          }
          props.notify_parent(data)
          handleClose();
        })        
        .catch(error => {
          message = null;
          reject()
        })
    );
    
    toast.promise(myPromise, {
      pending: "Loading...",
      success: message ?? defaultMessage,
      error: "Something went wrong. Please try again!"
    },
    //...
    )
    //this.setState({ rowData: data, loading: false });    
    
  }
 
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Crea Task Operativo
      </Button>
        <p>Seleziona le richieste da gestire nel nuovo task operativo</p>
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>          
          <Row>
            <Col md={10}>
            <Form onSubmit = {handleSubmit}>
                <Form.Group controlId="Titolo">
                    <Form.Label>Titolo</Form.Label>
                    <Form.Control 
                    type="text" 
                    name="Titolo"                    
                    placeholder="Inserisci il titolo del task" />
                </Form.Group>
                <Form.Group controlId="DataCreazione">
                    <Form.Label>Data Creazione</Form.Label>
                    <Form.Control 
                    type="date" 
                    name="DataCreazione"
                    placeholder="Inserisci la data di creazione" 
                    value= {dataCreazione}
                    onChange={(e) => setDataCreazione(e.target.value)}/>
                </Form.Group>
                <Form.Group controlId="DataChiusura">
                    <Form.Label>Data Chiusura</Form.Label>
                    <Form.Control 
                    type="date" 
                    name="DataChiusura"
                    placeholder="Inserisci la data di chiusura" 
                    value={dataChiusura}
                    onChange={(e) => setDataChiusura(e.target.value)}/>
                </Form.Group>
                <Form.Group controlId="UtenteOwner">
                    <Form.Label>Utente Owner</Form.Label>
                    <Form.Control 
                    type="text" 
                    name="UtenteOwner"                    
                    placeholder=""
                    readOnly 
                    defaultValue={username} />
                </Form.Group>
                <Form.Group controlId="Note">
                    <Form.Label>Note</Form.Label>
                    <Form.Control 
                    as="textarea"
                    rows={2}
                    name="Note"                    
                    placeholder="Inserisci le note" />
                </Form.Group>
                
                <br></br>
                <Form.Group>
                  <Button variant='primary' type='submit'>Crea Task</Button>
                </Form.Group>                
              </Form>
            </Col>
          </Row>
          <small>Le richieste selezionate verrano associate ad una nuova task, cliccare sul pulsante crea per confermare.</small>          
          <br></br>
        <div style={{height: '100%'}}>
          <div  id = "selectedRichiesteGrid"
            style = {{height: 250, weigth: 600}}
            className ="ag-theme-alpine">
                <GridRichiesteDetail sample={props.richieste}></GridRichiesteDetail>   
          </div>
        </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Chiudi
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateTaskModal;