import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { AgGridReact } from 'ag-grid-react';

import { Link } from 'react-router-dom';

import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-alpine.css"; // Theme

import Button from 'react-bootstrap/Button';
import { Container } from 'react-bootstrap';

import moment from 'moment';


const BtnAssegnatiComp = p=>{
  //const onTaskDetailClicked = useCallback( () => window.alert('Task ' + p.data.idTask));
  let id =  p.data.idTask;
  let stato =  p.data.stato;
  //let total_richieste =  p.data.richiesteTotal;
  //let total_assigned =  p.data.richiesteAssigned;
  let lnk = '/taskDetail/' + id + '/' + stato;
  
  return (     
    <div>          
      <Link to={lnk}>
      {/* <Button variant="primary" size='sm'>Assegnati ({total_assigned}/{total_richieste})</Button> */}
      <Button variant="primary" size='sm'>Gestisci</Button>
      </Link>        
    </div>
  )
}


export class Tasks extends Component {
  static displayName = Tasks.name;
  
  static displayName = "Tasks";

      // inizializzo data tabella richieste
  constructor(props) {
    super(props);
    this.state = {
      columnDefs : [
        // {headerName: 'ID Task',
        // field: 'idTask',
        // filter: 'agNumberColumnFilter',
        //  width: 30
        // },
        {headerName: "", 
        field: "idTask",
        width: 300,
        cellRenderer: BtnAssegnatiComp 
        },
        {headerName: 'Stato', 
         field: 'stato', 
         width: 50
        },          
        {headerName: 'Titolo',
         field: 'titolo',
         minWidth: 200
        },        
        {headerName: 'Data Creazione', 
         field: 'dataCreazione',
         width: 150,
         filter: 'agDateColumnFilter',
         filterParams: {
          // provide comparator function
          comparator: (filterLocalDateAtMidnight, cellValue) => {
              const dateAsString = cellValue;
              if (dateAsString == null) {
                  return 0;
              }                
              var dateFilterValue = moment(filterLocalDateAtMidnight).format('YYYY-MM-DD');
              // Now that both parameters are Date objects, we can compare
              var cellDateValue = moment(cellValue).format('YYYY-MM-DD');                                
              if (cellDateValue < dateFilterValue) {                    
                  return -1;
              } else if (cellDateValue > dateFilterValue) {                    
                  return 1;
              }                
              return 0;
          }
        },
         cellRenderer: (data) => {
            return moment(data.value).format('DD/MM/YYYY')
         }
        },
        {headerName: 'Data Chiusura', 
         field: 'dataChiusura',
         width: 150,
         filter: 'agDateColumnFilter',
         filterParams: {
          // provide comparator function
          comparator: (filterLocalDateAtMidnight, cellValue) => {
              const dateAsString = cellValue;
              if (dateAsString == null) {
                  return 0;
              }                
              var dateFilterValue = moment(filterLocalDateAtMidnight).format('YYYY-MM-DD');
              // Now that both parameters are Date objects, we can compare
              var cellDateValue = moment(cellValue).format('YYYY-MM-DD');                                
              if (cellDateValue < dateFilterValue) {                    
                  return -1;
              } else if (cellDateValue > dateFilterValue) {                    
                  return 1;
              }                
              return 0;
          }
        },
         cellRenderer: (data) => {
            return moment(data.value).format('DD/MM/YYYY')
          }
        },
        {headerName: 'Owner', 
         field: 'utenteOwner',
         width: 100,
         filter: 'agMultiColumnFilter'
        },
        {headerName: 'Note',
         field: 'note',
         width: 300
        },
        {
          headerName: 'Assegnate',
          field: 'richiesteAssigned',
          with: 50,
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Totale',
          field: 'richiesteTotal',
          with: 50,
          filter: 'agNumberColumnFilter',
        },        
      ],
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: 'agTextColumnFilter'
      },
      rowData: [], 
      loading: true,
      selectedRows : ''
      };    
  }

  onGridReady = (params) => {
    this.gridApi = params.api;    
    this.populateRichiesteData();    
  };

  async populateRichiesteData() {    
    const token = await authService.getAccessToken();    
    const response = await fetch('task/getTasks', {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });    
    const data = await response.json();    
    this.setState({ rowData: data, loading: false });
  };

render () {    
    return (
      <div>      
        <h3>Elenco Task Operativi</h3>
        <p>Gestione delle operazioni di evasione delle richieste fatte dai clienti in materia di GDPR</p>             
        <Container>
            <div style={{ height: '100%'}}>                        
            <div
              id = "taskGrid"
              style = {{ height: 800 }}
              className ="ag-theme-alpine"
            >
              <AgGridReact
                columnDefs={this.state.columnDefs}
                defaultColDef={this.state.defaultColDef}
                onGridReady={this.onGridReady}                
                rowData={this.state.rowData}
                rowSelection="multiple"
              />
        </div>
      </div>
        </Container>
      </div>
    );
  }
}
