import React  from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import Moment from 'react-moment';
import moment from 'moment';

import GridRichiesteDetail from './GridRichiesteDetail';

import authService from './api-authorization/AuthorizeService'
import { Form, Col, Row } from 'react-bootstrap';

import toast, { Toaster } from 'react-hot-toast';

function ModifyTaskRichiesteModal(props) {

  // handle modal component
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    props.notify_parent()
  }
    
  const [azione, setAzione] = useState('');    
  const [taskId, setTaskId] = useState(props.taskId);

  const handleSubmit = (event) => {    
    event.preventDefault();   
    modifyTaskRichieste(event);    
  }

  const modifyTaskRichieste = async function (event) {    
    const token = await authService.getAccessToken();
    // Simple POST request with a JSON body using fetch    
    const payload = {
                        "idTask": parseInt(taskId),
                        "richieste" : props.richieste,
                        "azione" : azione
                    };

    const requestOptions = {        
        method: 'POST',
        headers: new Headers ({
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json' 
         }),
        body: JSON.stringify(payload)
    };
    
    let message = null;
    const defaultMessage = 'Success!'

    const myPromise = new Promise((resolve, reject) =>
      fetch('richiesta/Azione', requestOptions)
        .then((res) => res.json())
        .then((data) => {                    
          if (data) {                        
            message = 'Azione asseganta con successo';            
            resolve();                     
          }
          props.notify_parent(message)
          handleClose();
        })        
        .catch(error => {
          message = null;
          reject()
        })
    );
    
    toast.promise(myPromise, {
      pending: "Loading...",
      success: message ?? defaultMessage,
      error: "Something went wrong. Please try again!"
    },
    //...
    )
    //this.setState({ rowData: data, loading: false });    
    
  }
  // console.log('props title')
  // console.log(props.title)

  return (
    <>
      <Button variant="primary" onClick={handleShow} data-bs-toggle="tooltip" data-bs-placement="top" title="Selezionare le richieste da assegnare con i checkbox">
        Assegna azione
      </Button>
        {/* <p>Seleziona le richieste da assegnare una azione</p> */}
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>          
          <Row>
            <Col md={12}>
            <Form onSubmit = {handleSubmit}>                
                <Form.Group controlId="formTaskAzioneSelect">
                  <Form.Label>Scegliere l'azione da svolgere per le richieste selezionate:</Form.Label>
                  <Form.Control
                    as="select"
                    value={azione}
                    onChange={e => {
                      console.log("e.target.value", e.target.value);
                      setAzione(e.target.value);
                    }}
                  >
                    <option value=""></option>
                    <option value="ACCESSO_PDF">Accesso (PDF)</option>
                    <option value="ACCESSO_TXT">Accesso (TXT)</option>
                    <option value="CANCELLAZIONE">Cancellazione</option>
                    <option value="OPPOSIZIONE">Opposizione</option>
                  </Form.Control>
                </Form.Group>                              
                <br></br>
                <Form.Group>
                  <div className='text-center'>
                  <Button class="btn btn-lg btn-primary" type='submit'>Conferma</Button>                  
                  </div>                  
                </Form.Group>  
                <br></br>
              </Form>
            </Col>
          </Row>          
          <br></br>
        <div style={{height: '100%'}}>
          <div  id = "selectedRichiesteGrid"
            style = {{height: 250, weigth: 600}}
            className ="ag-theme-alpine">
                <GridRichiesteDetail sample={props.richieste}></GridRichiesteDetail>   
          </div>
        </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Chiudi
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModifyTaskRichiesteModal;