import React, { useState } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Theme

// Create new GridRichiesteDetail component
function GridRichiesteDetail({sample}) {
  // Row Data: The data to be displayed.
  let data = sample
  const [rowData, setRowData] = useState(data);

  // Column Definitions: Defines & controls grid columns.
  const [colDefs, setColDefs] = useState([
    { field: 'idRichiesta', width: 150 },
    { field: 'cognome', width: 150 },
    { field: 'nome', width: 150 },
    { field: 'idDiritto' , width: 150 },
    { field: 'userId', width: 150 },
    { field: 'email' , width: 150 },
    { headerName: 'Data Inserimento', field: 'dataInsert' , width: 150 }
  ]);
 // Container: Defines the grid's theme & dimensions.
 

 return (
  <div
    className={
      "ag-theme-quartz-dark"
    }
    style={{ width: '100%', height: '100%' }}
  >
    <AgGridReact rowData={rowData} columnDefs={colDefs} />
  </div>
);
}

export default GridRichiesteDetail;