import React, { Component} from 'react';

import authService from './api-authorization/AuthorizeService'
import ModifyTaskRichiesteModal from './ModifyTaskRichiesteModal';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import toast, { Toaster } from 'react-hot-toast';
import { Col, Row } from 'react-bootstrap';

import moment from 'moment';

import Button from 'react-bootstrap/Button';



export class TaskRichieste extends Component {
   
  static displayName = TaskRichieste.name;
  
  btnDownloadUserReport = p=>{
    //const onTaskDetailClicked = useCallback( () => window.alert('Task ' + p.data.idTask));
    let idRichiesta =  p.data.idRichiesta;
    let azione =  p.data.azione;
    let email =  p.data.email;    
    if (azione === 'ACCESSO' || azione === 'ACCESSO_PDF' || azione === 'ACCESSO_TXT') {
      return (     
        <div>          
          <Button onClick={() => {this.handleDownload(email, idRichiesta, azione)}}>Download</Button>
        </div>
      )
    }else{
      return null;
    }    
  };

  async handleDownload(email, idRichiesta, azione) {    
    const token = await authService.getAccessToken();   
    console.log('download azione ' + azione);
    var url = '';
    var type = '';
    var ext = '';
    if (azione === 'ACCESSO' || azione === 'ACCESSO_PDF'){
      url =  'richiesta/GetUserReportAsPdf?userId=' + email + '&idRichiesta='+ idRichiesta + '&azione=' + azione;
      type = 'application/pdf';
      ext = 'pdf';
    }else if (azione === 'ACCESSO_TXT'){
      url = 'richiesta/GetUserReportAsTxt?userId=' + email + '&idRichiesta='+ idRichiesta + '&azione=' + azione;
      type = 'application/octet-stream';
      ext = 'txt';
    }
    console.log('download type ' + type);
    const options = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    await fetch(url, options, type, ext).then(function(response) {
      return response.blob();
      }).then(function(myBlob) {
        //console.log(myBlob);      
          let filename = email;          
          const blob = new Blob([myBlob], { type: type});
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          //link.download = `${filename}-${+new Date()}.pdf`;
          link.download = `${filename}-${+new Date()}.${ext}`;
          link.click();
          window.URL.revokeObjectURL(link.href);
      }).then(
      function() {
            window.setTimeout(function() {
            //document.querySelector('#pdf-frame').contentWindow.print();
            console.log('timeout');
          }, 1000)
      });
  };

  // inizializzo data tabella richieste
  constructor(props) {
    super(props);
    this.state = {
      columnDefs : [
        { headerName: 'ID Richiesta',          
          field: 'idRichiesta',
          filter: 'agNumberColumnFilter',
          width: 100,
          headerCheckboxSelection: true,
          checkboxSelection: true,
          showDisabledCheckboxes: true
        },
        { headerName: 'Stato Elaborazione',
          field: 'statoElaborazione',
          width: 150
        },
        { headerName: 'Azione',
          field: 'azione',
          width: 150
        },         
        { headerName: "Allegati", 
          field: "idRichiesta",
          width: 200,
          cellRenderer: this.btnDownloadUserReport 
        },
        { headerName: 'Email',
          field: 'email',
          minWidth: 250
        },
        { headerName: 'User ID',
          field: 'userId', 
          filter: 'agNumberColumnFilter',
          width: 250
        },              
        { headerName: 'Cognome', 
          field: 'cognome', 
          width: 200
        },
        { headerName: 'nome', 
          field: 'nome', 
          width: 200
        },
        { headerName: 'IdDiritto', 
          field: 'idDiritto', 
          width: 200
        },
        { headerName: 'Descrizione', 
          field: 'descrizione', 
          width: 200
        },
        { headerName: 'Note',
          field: 'note',
          width: 200
        },
        { headerName: 'Data Richiesta', 
          field: 'dataInsert',
          width: 100,
          filter: 'agDateColumnFilter',
          cellRenderer: (data) => {
            return moment(data.value).format('DD/MM/YYYY')
          },          
        }
    ],
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: 'agTextColumnFilter'
      },
      rowData: [], 
      loading: true,
      selectedRows : '',
      idTask: props.idTask,
      statoTask: props.statoTask
      };    
  }
  
  onFilterChanged = ev => {
    console.log('on filter changed')
    if (ev?.api?.rowModel?.rowsToDisplay) {
      this.setState({ selectedRows: ev?.api?.rowModel?.rowsToDisplay.filter(node => node.isSelected()) });
      //const selectedData =  this.gridApi.getSelectedNodes().filter(node => node.isSelected).map(node => node.data);       
    //this.setState({selectedRows: selectedData})       
    }
  };

  onSelectionChanged = () => {
    console.log('on selectionchanged')
    //const selectedData = this.gridApi.getSelectedRows();

    //const selectedData = this.gridApi.getSelectedNodes().filter(node => node.displayed).map(node => node.data);      
    const selectedData =  this.gridApi.getSelectedNodes().filter(node => node.isSelected && node.displayed).map(node => node.data);       
    this.setState({selectedRows: selectedData})       
  };


  onGridReady = (params) => {
    this.gridApi = params.api;    
    this.populateRichiesteData();  
      // Set a filter model      
    // this.gridApi.setColumnFilterModel('idTask', {
    //   filterType: 'text',
    //   type: 'startsWith',
    //   filter: null,
    // });
    
    // Tell grid to run filter operation again
    //this.gridApi.onFilterChanged();
  };

  isRowSelectable = (params) => {          
      return this.state.statoTask !== 'SUBMITTED';
    }

  async populateRichiesteData() {    
    const token = await authService.getAccessToken();    
    const url = 'richiesta/getRichiesteByTaskId?idTask=' + this.state.idTask;
    const response = await fetch(url, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });    
    const data = await response.json();    
    this.setState({ rowData: data, loading: false });
  }

  notify = (resp_data) => {  
    if (resp_data){          
      toast(resp_data);  
      this.populateRichiesteData();              
    }  
  }
  // callback from modal after modifica azione su richieste
  notify_azione = (newtask) => {    
    if(newtask){
      this.notify(newtask);    
    }    
  }    

  // callback from modal after modifica azione su richieste
  // notify_update_stato = (data) => {  
  //   if(data){
  //     console.log(data);
  //     this.notify('update stato task');    
  //   }
    
  // }  

  //gridRef = useRef();
  // filters
 clearFilters = () => {
    this.gridApi.setFilterModel(null);
  };

  // sideBar = useMemo(() => { 
  //   return 'filters';
  // }, []);

  // set background colour on every row, this is probably bad, should be using CSS classes
   
   rowStyle = { background: 'none' };
  // set background colour on even rows again, this looks bad, should be using CSS classes
  getRowStyle = p => {
    if (p.data.userId === null) {
        return { background: 'orange' };
    }
  };

  render() {

    return (
      <div style={{ height: '100%'}}>               
        <Toaster /> 
        <br>
        </br> 
        <Row>
          <Col md={2}>
            <ModifyTaskRichiesteModal title="Modifica Richieste" richieste={this.state.selectedRows} notify_parent = {this.notify_azione}  taskId={this.state.idTask}/>      
          </Col>
        </Row>     
        <br>
        </br>
        <div
          id = "taskRichiesteGrid"
          style = {{ height: 800 }}
          className ="ag-theme-alpine"
        >
          <AgGridReact
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            onGridReady={this.onGridReady}
            onSelectionChanged={this.onSelectionChanged}
            onFilterChanged={this.onFilterChanged}
            rowData={this.state.rowData}
            rowSelection="multiple"
            suppressRowClickSelection = "true"
            isRowSelectable={this.isRowSelectable}       
            rowStyle={this.rowStyle}
            getRowStyle={this.getRowStyle}     
          />  
        </div>
      </div>
    );
  }
}

//render(<Richieste></Richieste>, document.querySelector('#root'));
