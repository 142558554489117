import React, { useEffect, useState, useCallback } from "react"; 
import { Form, Col, Row} from 'react-bootstrap';
import authService from './api-authorization/AuthorizeService'

import ModifyTaskStatusModal from './ModifyTaskStatusModal';
import toast, { Toaster } from 'react-hot-toast';

import moment from 'moment';

const TaskProfileComponent = (props) => {
    
    
    // user state for form
    const [idTask, setIdTask] = useState(0);
    const [titoloTask, setTitoloTask] = useState('');
    const [ownerTask, setOwnerTask] = useState('');
    const [statoTask, setStatoTask] = useState('');
    const [dataCreazioneTask, setDataCreazioneTask] = useState('');
    const [dataChiusuraTask, setDataChiusuraTask] = useState('');
    const [noteTask, setNoteTask] = useState('');
    
    
    useEffect(() => {
       const id = props.id;
       const fetchData = async () => {
        const token = await authService.getAccessToken();    
        const response = await fetch('task/getTask?id='+ id, {
          headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });    
        const dataTask = await response.json();    
        setDataTask(dataTask);
        
       }
       // call the function
       fetchData()       
       // make sure to catch any error
       .catch(console.error);
    });
   
    
    function setDataTask (data) {
        if(data){
            console.log(data);
            setIdTask(data.idTask);
            setStatoTask(data.stato);
            setOwnerTask(data.utenteOwner);
            setTitoloTask(data.titolo);
            setDataCreazioneTask(moment(data.dataCreazione).format("DD-MM-YYYY"));
            setDataChiusuraTask(moment(data.dataChiusura).format("DD-MM-YYYY"));
            setNoteTask(data.note);
        }        
    }
    
    const loadDataOnlyOnce = useCallback(() => {
        console.log(`I need task profile data!!`);
      }, []);
  
      useEffect(() => {
          loadDataOnlyOnce(); // this will fire only when loadDataOnlyOnce-reference changes
      }, [loadDataOnlyOnce]);

  // callback from modal after modifica azione su richieste
  const notify_update_stato = (data) => {  
    if(data){      
      //this.notify('update stato task'); 
      console.log('ricarico pagina');
      window.location.replace('/taskDetail/' + idTask + '/' + statoTask);   
      //window.location.reload(true);   
      console.log('aggiorno dati stato task');
    }    
  }  
      
    return ( 
        <div>
            <h1>Task #{idTask} {titoloTask}</h1>   
            <br></br>
            <Form>
                <Row>
                    <Col>
                        <Form.Group     className="w-100" controlId="taskForm.ControlInput1">
                            <Form.Label>Stato</Form.Label>
                            <Form.Control type="text" readOnly defaultValue={statoTask}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="taskForm.ControlInput2">
                            <Form.Label>Owner</Form.Label>
                            <Form.Control type="text" defaultValue={ownerTask} readOnly />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="taskForm.ControlInput3">
                            <Form.Label>Data Creazione</Form.Label>
                            <Form.Control type="text" defaultValue={dataCreazioneTask} readOnly />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="taskForm.ControlInput4">
                            <Form.Label>Data Chiusura</Form.Label>
                            <Form.Control type="text" defaultValue={dataChiusuraTask} readOnly />
                        </Form.Group>
                    </Col>
                </Row>                
                <Row>
                    <Form.Group controlId="taskForm.ControlTextarea1">
                        <Form.Label>Note</Form.Label>
                        <Form.Control as="textarea" rows={2} defaultValue={noteTask} readOnly/>
                    </Form.Group>
                </Row>
            </Form>
            {/* button actions */}
            <div>
                <Toaster /> 
                <br>
                </br> 
                <div class="d-flex justify-content-end">                                
                    <ModifyTaskStatusModal title="Chiusura Ticket" notify_parent = {notify_update_stato}  taskId={props.id}/>                                    
                </div>     
            </div>
        </div>
    );
  };

export default TaskProfileComponent;
