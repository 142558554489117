import React, { Component, useEffect} from 'react';

import authService from './api-authorization/AuthorizeService'

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import CreateTaskModal from './CreateTaskModal';

import toast, { Toaster } from 'react-hot-toast';

import moment from 'moment';

export class Richieste extends Component {
   
  static displayName = Richieste.name;
  

  // inizializzo data tabella richieste
  constructor(props) {
    super(props);
    this.state = {
      columnDefs : [
        {headerName: 'ID Richiesta',
          field: 'idRichiesta',
          filter: 'agNumberColumnFilter',
          width: 50,
          headerCheckboxSelection: true,
          checkboxSelection: true,
          showDisabledCheckboxes: true
        },
        {headerName: 'Email',
          field: 'email',
          minWidth: 250
        },
        {headerName: 'User ID',
          field: 'userId', 
          filter: 'agNumberColumnFilter',
          width: 250
        },              
        {headerName: 'Cognome', 
          field: 'cognome', 
          width: 200
        },
        {headerName: 'Nome', 
          field: 'nome', 
          width: 200
        },
        {headerName: 'ID Diritto', 
          field: 'idDiritto', 
          filter: 'agNumberColumnFilter',
          width: 200
        },
        {headerName: 'Descrizione', 
          field: 'descrizione', 
          width: 200
        },
        {headerName: 'Note',
        field: 'note',
        width: 200
      },
        {headerName: 'Azione',
          field: 'azione',
          width: 150
        },
        {headerName: 'Data Richiesta', 
          field: 'dataInsert',
          width: 200,
          filter: 'agDateColumnFilter',          
          filterParams: {
            // provide comparator function
            comparator: (filterLocalDateAtMidnight, cellValue) => {
                const dateAsString = cellValue;
                if (dateAsString == null) {
                    return 0;
                }                
                var dateFilterValue = moment(filterLocalDateAtMidnight).format('YYYY-MM-DD');
                // Now that both parameters are Date objects, we can compare
                var cellDateValue = moment(cellValue).format('YYYY-MM-DD');                                
                if (cellDateValue < dateFilterValue) {                    
                    return -1;
                } else if (cellDateValue > dateFilterValue) {                    
                    return 1;
                }                
                return 0;
            }
        },
          cellRenderer: (data) => {
            return moment(data.value).format('DD/MM/YYYY')
          }
        },      
      {headerName: 'Stato', 
        field: 'statoElaborazione', 
        width: 250
      },          
      {headerName: 'ID Task', 
        field: 'idTask', 
        width: 50
      },
      {headerName: 'Titolo Task', 
      field: 'titoloTask', 
      width: 150
      },
      {headerName: 'Owner Task', 
      field: 'utenteOwnerTask', 
      width: 50
      }],
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: 'agTextColumnFilter'
      },
      rowData: [], 
      loading: true,
      selectedRows: ''      
      };    
  }

  // getSelectedRowData = () => {
  //   let selectedData = this.gridApi.getSelectedRows();
  //   //alert(`Selected Nodes:\n${JSON.stringify(selectedData)}`);    
  //   //console.log('`Selected Nodes:\n${JSON.stringify(selectedData)}');
  //   this.setState({selectedRows: selectedData})    
  //   return selectedData;
  // };

  // onSelectionChanged = () => {
  //   const selectedData = this.gridApi.getSelectedRows();
  //   this.setState({selectedRows: selectedData})       
  // }; 

  onFilterChanged = ev => {
    console.log('on filter changed')
    if (ev?.api?.rowModel?.rowsToDisplay) {
      this.setState({ selectedRows: ev?.api?.rowModel?.rowsToDisplay.filter(node => node.isSelected()) });
      //const selectedData =  this.gridApi.getSelectedNodes().filter(node => node.isSelected).map(node => node.data);       
    //this.setState({selectedRows: selectedData})       
    }
  };

  onSelectionChanged = () => {
    console.log('on selectionchanged')
    //const selectedData = this.gridApi.getSelectedRows();

    //const selectedData = this.gridApi.getSelectedNodes().filter(node => node.displayed).map(node => node.data);      
    const selectedData =  this.gridApi.getSelectedNodes().filter(node => node.isSelected && node.displayed).map(node => node.data);       
    this.setState({selectedRows: selectedData})       
  };

  onGridReady = (params) => {
    this.gridApi = params.api;    
    this.populateRichiesteData();  
      // Set a filter model      
    // this.gridApi.setColumnFilterModel('idTask', {
    //   filterType: 'text',
    //   type: 'startsWith',
    //   filter: null,
    // });
    
    // Tell grid to run filter operation again
    //this.gridApi.onFilterChanged();
  };

  isRowSelectable = (params) => {
      return !!params.data && !params.data.idTask;
    }

  async populateRichiesteData() {    
    const token = await authService.getAccessToken();    
    const response = await fetch('richiesta/getRichieste', {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });    
    const data = await response.json();    
    this.setState({ rowData: data, loading: false });
  }

  notify = (resp_data) => {  
    if (resp_data){    
      var rd = JSON.stringify(resp_data);
      var resp = JSON.parse(rd);

      console.log('resp sottomettere');
      console.log(resp);
      
      var toast_msg = 'Creata Task ID: ' + resp.idTask;
      toast(toast_msg);
       
      // redirect to task detail id
      if(resp.idTask > 0){                              
        window.location.replace('/taskDetail/' + resp.idTask + '/' + resp.stato );                    
      }else{
        // refresh elenco
        this.populateRichiesteData();    
      }     
    }  
  }  
  // callback from modal
  notifyme = (newtask) => {    
    this.notify(newtask);    
  }
  
  //gridRef = useRef();
  // filters
 clearFilters = () => {
    this.gridApi.setFilterModel(null);
  };

  // sideBar = useMemo(() => { 
  //   return 'filters';
  // }, []);

  render() {

    return (
      <div style={{ height: '100%'}}>
        <Toaster />     
        <h3>Elenco Richieste</h3>   
        <CreateTaskModal title="Nuovo Task" richieste={this.state.selectedRows} notify_parent = {this.notifyme} />      
        <div>                    
          {/* <div className="button-group">            
            <Button variant="primary" onClick={this.clearFilters}>
                Reset Filters
              </Button>
            <button onClick={this.clearFilters}>Reset Filters</button>
          </div> */}
        </div>
        <div
          id = "richiesteGrid"
          style = {{ height: 800 }}
          className ="ag-theme-alpine"
        >
          <AgGridReact
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            onGridReady={this.onGridReady}
            onSelectionChanged={this.onSelectionChanged}
            onFilterChanged={this.onFilterChanged}
            rowData={this.state.rowData}
            rowSelection="multiple"            
            isRowSelectable={this.isRowSelectable}            
          />
        </div>
      </div>
    );
  }
}

//render(<Richieste></Richieste>, document.querySelector('#root'));
