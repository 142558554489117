import React  from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import authService from './api-authorization/AuthorizeService'
import { Form, Col, Row } from 'react-bootstrap';

import toast, { Toaster } from 'react-hot-toast';

function ModifyTaskStatusModal(props) {

  // handle modal component
  const [taskStatus, setAzione] = useState('');    
  const [taskId, setTaskId] = useState(props.taskId);  
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (event) => {
    event.preventDefault();   
    setShow(true);
    //props.notify_parent('taskStatus');
  }

  const handleSubmit = (event) => {    
    event.preventDefault();   
    modifyTaskRichieste(event); 
    setShow(false);
  }

  const modifyTaskRichieste = async function (event) {    
    event.preventDefault();  
    const token = await authService.getAccessToken();
    // Simple POST request with a JSON body using fetch    
    const payload = {
                        "id": parseInt(taskId),                        
                        "stato" : taskStatus
                    };
    
    const requestOptions = {        
        method: 'POST',
        headers: new Headers ({
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json' 
         }),
        body: JSON.stringify(payload)
    };
    
    let message = null;
    const defaultMessage = 'Success!'

    const myPromise = new Promise((resolve, reject) =>
      fetch('task/closeTask', requestOptions)
        .then((res) => res.json())
        .then((data) => {                    
          if (data) {                        
            message = 'Task sottomesso con successo';                        
            resolve();                     
          } 
          handleClose();         
        })        
        .catch(error => {
          message = null;
          reject()
        })
        //.finally( () => props.notify_parent('message') )
    );
    
    toast.promise(myPromise, {
      duration: 4000,
      pending: "Loading...",
      //success: message ?? defaultMessage,      
      success: (data) => {
          props.notify_parent('message yyy')       
      },
      error: "Something went wrong. Please try again!"
    },
    //..
    )
    //this.setState({ rowData: data, loading: false });  
    
  }


  return (
    <>
      <Button variant="primary  pull-rigth" onClick={handleShow} data-bs-toggle="tooltip" data-bs-placement="top" title="">
        Sottomettere Richieste
      </Button>        
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>          
          <Row>
            <Col md={12}>
            <Form onSubmit = {handleSubmit}>                
                <Form.Group controlId="formTaskStatusSelect">
                  <Form.Label>Scegliere lo stato da impostare alla Task:</Form.Label>
                  <Form.Control
                    as="select"
                    value={taskStatus}
                    onChange={e => {
                      console.log("e.target.value", e.target.value);
                      setAzione(e.target.value);
                    }}
                  >
                    <option value=""></option>
                    <option value="SUBMITTED">Sottomettere</option>                    
                  </Form.Control>
                </Form.Group>                              
                <br></br>
                <Form.Group>
                  <div className='text-center'>
                    <Button variant="primary" type='submit'>Conferma</Button>
                  </div>                  
                </Form.Group>                
              </Form>
            </Col>
          </Row>          
          <br></br>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Chiudi
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModifyTaskStatusModal;