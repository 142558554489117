import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Tasks } from './components/Tasks';  // home page

import { Richieste } from './components/Richieste';
import { Counter } from './components/Counter';
import { TaskDetail } from './components/TaskDetail';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import './custom.css' 


export default class App extends Component {    
    static displayName = App.name;

  render () {
    return (
      <Layout>
        <AuthorizeRoute exact path='/' component={Tasks} />
        <Route path='/counter' component={Counter} />
        <AuthorizeRoute path='/richieste' component={Richieste} />
        <AuthorizeRoute path='/taskDetail/:id/:stato' component={TaskDetail} />
        {/* <AuthorizeRoute path='/fetch-data' component={FetchData} /> */}
        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
      </Layout>
    );
  }
}
